import React from "react";


const ErrorModal = ({ closeModal }) => {

    return (
        <div className="errorModalWrapper">
            <div className="buttonWrapper">
                <button className="closeButton" onClick={() => closeModal()}>X</button>
            </div>

            <div className="errorInner">
                <h3>Whoops...</h3>
                <p>It seems that an error has occurred. Please try again later or contact support for assistance.</p>
            </div>

        </div>
    )

}

export default ErrorModal;