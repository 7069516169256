import React from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import './App.css';


const LandingPage = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const jwtToken = queryParams.get('j');
    const terminalCode = queryParams.get('t');
    const deviceId = queryParams.get('d');

    Cookies.set('jwtToken', jwtToken);
    Cookies.set('terminalCode', terminalCode);
    Cookies.set('deviceId', deviceId);


    const baseUrl = "https://cashreg.financeincorp.com";
    const redirectUri = baseUrl + '/getRegisters';


    const uri = `https://secure.vendhq.com/connect?response_type=code&client_id=julh7HBiwdnzYtHeJBXfxm12fZ1VeJJu&redirect_uri=${redirectUri}&state=sadasfasfasf145`;


    return (
        <div className="landingWrapper">
            <h1 className="mb-4">Link PaymixSoftPOS to Cash Register</h1>
            <a href={uri} className="mt-5 mb-5 linkButton" style={{ marginLeft: 0 }}>
                Lightspeed
            </a>
            <p>More cash register methods are coming soon.</p>
        </div>
    )
}

export default LandingPage;