import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { saveRegisterList, getRegisterList, addTerminal } from './API/index';
import './App.css';
import Cookies from "js-cookie";
import ErrorModal from "./ErrorModal";


const CallbackPage = () => {


    const [list, setList] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const code = queryParams.get('code');
    const domainPrefix = queryParams.get('domain_prefix');
    const state = queryParams.get('state');
    const userId = queryParams.get('user_id');


    useEffect(() => {

        fetchData();

    }, []);


    const fetchData = () => {
        console.log("userId")
        console.log(userId)
        console.log("code")
        console.log(code)
        console.log("domainPrefix")
        console.log(domainPrefix)
        saveRegisterList(code, domainPrefix, state, userId).then((res) => {
            console.log("res")
            console.log(res)
            if (res.success) {
                getRegisterList().then((res) => {
                    if (res.payload !== null) {
                        setIsLoading(false)
                        setList(res.payload);
                    }
                }).catch((error) => {
                    setIsError(true);
                    console.error("error 1");
                    console.error(error);
                })
            }
        }).catch((error) => {
            setIsError(true);
            console.error("error 2");
            console.error(error);
        })
    }

    const handleLinkButton = (cashRegisterId) => {

        let terminalCode = Cookies.get('terminalCode');
        let deviceId = Cookies.get('deviceId');

        addTerminal(cashRegisterId, deviceId, terminalCode).then((res) => {
            if (res.success) {
                setIsLoading(false);
                setIsSuccess(true);

            }
        }).catch((error) => {
            setIsError(true);
            console.error(error)
        })
    }

    const closeModal = () => {
        setIsError(false)
    }
    
    if (isError) {
        return (
            <ErrorModal closeModal={() => closeModal()} />
        )
    }

    return (
        <div className="terminalWrapper">
            <h1>Cash Registers</h1>

            {isLoading ?

                <div className="loadingWrapper">Loading...</div>
                :
                (list && list.length > 0) ? (
                    <div>
                        {list?.map((listItem, index) => (
                            <div key={index} className="listView">
                                <div>{listItem.cashRegisterName}</div>

                                {isSuccess ?
                                    <div className="thankMsg">Thank You</div>
                                    :
                                    <button className="linkButton" onClick={() => handleLinkButton(listItem.cashRegisterId)}>
                                        Link
                                    </button>
                                }

                            </div>
                        ))}
                    </div>
                ) : (
                    <p>You don't have any cash registers to link..</p>
                )

            }

        </div>
    );
}

export default CallbackPage;