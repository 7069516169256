import Cookies from 'js-cookie';
import { HEADERS } from "../Constants";
import { URL_SAVE_REGISTER, URL_GET_REGISTER, URL_ADD_TERMINAL } from "../UrlList";

import axios from './axiosInstance'

export const saveRegisterList = async (code, domainPrefix, state, userId) => {

    try {
        const { data } = await axios.get(URL_SAVE_REGISTER(code, domainPrefix, state, userId), { headers: HEADERS });

        return data;

    } catch (error) {
        console.error(error)

    }
}


export const getRegisterList = async () => {

    try {
        const { data } = await axios.get(URL_GET_REGISTER, { headers: HEADERS });

        return data;

    } catch (error) {

        console.error(error)
    }
}

export const addTerminal = async (registerId, deviceId, terminalCode) => {


    const terminalData = {
        deviceId: deviceId,
        terminalCode: terminalCode,
        registerId: registerId
    };

    try {
        const { data } = await axios.post(URL_ADD_TERMINAL, terminalData, {
            headers: HEADERS,
        });
        Cookies.remove('jwtToken');
        Cookies.remove('terminalCode');
        Cookies.remove('deviceId');
        return data;

    } catch (error) {

        console.error(error)
    }
}
