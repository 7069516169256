import './App.css';
import React, { useEffect } from 'react'
import LandingPage from './LandingPage';
import CallbackPage from './CallbackPage';
import { Routes, Route } from "react-router-dom";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
    autoCapture: true
};
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AZURE_APP_INSIGHTS,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
            [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});

appInsights.loadAppInsights();


const App = () => {
    useEffect(() => {
    }, [])

    return (
        <AppInsightsErrorBoundary onError={() => <h1>Something went wrong..</h1>} appInsights={reactPlugin}>
            <Routes>
                <Route index element={<LandingPage />} />
                <Route path="getRegisters" element={<CallbackPage />} />
            </Routes>
        </AppInsightsErrorBoundary>
    )
}
export default withAITracking(reactPlugin, App);
